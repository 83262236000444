import React from 'react';
import Layout from '../Layout';
import "./style.scss";
import img1 from "../../images/corporate_logo1.png"
import img2 from "../../images/corporate_logo2.png"
import img3 from "../../images/corporate_logo3.png"
import img4 from "../../images/corporate-logo-4.jpg"
import img5 from "../../images/corporate-logo-5.jpg"
import img6 from "../../images/corporate-logo-6.jpg"
import img7 from "../../images/corporate-logo-7.jpg"
import img8 from "../../images/corporate-logo-8.jpg"


const PartnersPage = () => {

    const data = [
        { link: "https://www.allcargologistics.com/", img: img2 },
        { link: "https://www.ambit.co/", img: img5 },
        { link: "https://www.bharatpetroleum.in/", img: img8 },
        // { link: "https://www.capitaland.com/sites/ascendas-firstspace/index.html", img: img3 },
        { link: "https://www.chalethotels.com/", img: img1 },
        { link: "", img: img6 },
        { link: "https://www.iotl.com/", img: img4 },
        { link: "https://www.primetals.com/", img: img7 }

    ]
    return (
        <Layout>
            <div className='partners'>
                <div className='content'>
                    <h1>Partners</h1>
                    <div className="divider"></div>
                    <br />
                    <p>
                    </p>

                    <div className='partners__logos'>
                        {data.map(item => <a target="_blank" href={item.link.length === 0 ? undefined : item.link}><img src={item.img}></img></a>)}
                    </div>
                </div>


            </div>
        </Layout>
    );
}

export default PartnersPage;
